/* eslint-disable import/no-extraneous-dependencies */
import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Layout from "../components/layout"
import Title from "../components/title"
import Carousel, { Dots } from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"
import { Typography, Box, Container } from "@material-ui/core"

import styled from "styled-components"

const CarouselWrapper = styled.div`
  width: 100%;
  height: ${(props) => props.desktopHeight};
  margin-bottom: 20px;

  & .BrainhubCarousel__arrows {
    background-color: #a90000;
  }

  & .gatsby-image-carousel-wrapper {
    // carousel container is height:auto, so children need to have set height
    height: ${(props) => props.desktopHeight};
    width: 100%; // 100% of width of the Carousel set container
    padding: 2%;
    // setting gatsby image to 100x100%
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: 600px) {
    height: ${(props) => props.mobileHeight};
    & .gatsby-image-carousel-wrapper {
      height: ${(props) => props.mobileHeight};
    }
  }
`

CarouselWrapper.defaultProps = {
  desktopHeight: "600px",
  mobileHeight: "350px",
}

const IgraonicaPage = ({ data }) => {
  const images = data.allFile.nodes
  return (
    <Layout fixed title="Dječja igraonica">
      <CarouselWrapper desktopHeight="500px">
        <Carousel
          arrows
          infinite
          draggable
          centered
          autoPlay={1500}
          stopAutoPlayOnHover
          slidesPerPage={1}
        >
          {images.map((img, idx) => (
            <div key={idx} class="gatsby-image-carousel-wrapper">
              <Img fluid={img.childImageSharp.fluid} objectFit="contain" />
            </div>
          ))}
        </Carousel>
      </CarouselWrapper>
      <Container maxWidth="sm" component={Box} px={4}>
        <Typography paragraph variant="h4" color="primary" align="center">
          Dječja igraonica
        </Typography>
        <Typography variant="body1">
          Za naše najmlađe u ponudi imamo igraonicu gdje mališani kroz igru i
          smijeh uče prve riječi engleskog ili njemačkog jezika.
          <br />
          Cilj je djetetu od najranije dobi isključiti strah od nepoznatog i
          kroz igru potaknuti sklonost učenja stranog jezika. <br />
          Naše učiteljice znaju kako osvojiti dječju pažnju i uvesti ih u
          čarobni svijet učenja stranih kultura. <br />
          Igraonice su za djecu od 4 do 6 godina.
        </Typography>
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "gloria/playschool" } }) {
      nodes {
        childImageSharp {
          fluid(
            maxWidth: 1200
            srcSetBreakpoints: [200, 340, 520, 890]
            quality: 80
          ) {
            ...GatsbyImageSharpFluid
            originalName
            aspectRatio
          }
        }
      }
    }
  }
`

export default IgraonicaPage
